import { defineStore } from 'pinia'
import { useShadowStore, useEventHandlerStore } from '@/stores'

export const useWebsocketStore = defineStore('websocket', {
  state: () => ({
    // ws: 儲存 WebSocket 連線
    // wsMessage: 儲存從 WebSocket 接收到的資料
    // isConnected: 追蹤 WebSocket 是否連線
    // connectionTimestamp: 儲存 WebSocket 連線的時間戳記
    ws: null as WebSocket | null,
    wsMessage: null as MessageEvent | null,
    isConnected: false,
    connectionTimestamp: 0
  }),
  actions: {
    // connect(): 建立 WebSocket 連線
    // disconnect(): 關閉 WebSocket 連線
    connect () {
      if (this.ws) return
      const wsUrl = 'wss://x152w5dczj.execute-api.ap-northeast-1.amazonaws.com/production'
      const shadowStore = useShadowStore()
      const eventHandlerStore = useEventHandlerStore()

      this.ws = new WebSocket(wsUrl)
      this.ws.onopen = () => {
        this.isConnected = true
        this.sendWebsocket(shadowStore.thingNameList)
      }
      this.ws.onclose = () => {
        this.isConnected = false
        this.connectionTimestamp = 0
      }
      this.ws.onmessage = (event: MessageEvent) => {
        const message = JSON.parse(event.data)
        this.handleWebSocketMessage(message)
      }
      if (this.ws) {
        const logData = {
          path: 'websocket.ts',
          action: '主動連線 websocket',
          info: '已建立 websocket 連線'
        }
        eventHandlerStore.setLogData(logData)
      }
    },
    sendWebsocket (thingNameList: string[]) {
      const message = JSON.stringify({
        action: 'registryThing',
        thingName: thingNameList
      })
      this.ws?.send(message)
    },
    disconnect () {
      if (this.ws) {
        this.ws.close()
        this.isConnected = false
        this.connectionTimestamp = 0
        this.ws = null
        const eventHandlerStore = useEventHandlerStore()
        const logData = {
          path: 'websocket.ts',
          action: '取消 websocket 連線',
          info: JSON.stringify(this.ws)
        }
        eventHandlerStore.setLogData(logData)
      }
    },
    reconnect () {
      this.disconnect()
      this.connect()
    },
    handleWebSocketMessage (message: MessageEvent) {
      this.wsMessage = message
    }
  }
})
