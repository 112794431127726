import {
  convertTaipeiTimestampToDate,
  convertTimestampToTaipeiTimestamp,
  taiwanCurrentDateTimestamp
} from '@/plugins/TimeConvert'
import type {DatePickerType} from '@/interface/DatePickerType'

/**
 * 產生預設的單一日期值。
 * 轉換成台北時間後，再減去一天的時間戳記
 * @returns {number} - 預設的單一日期值的時間戳記。
 */

export const defaultSingleValue = (): number => {
  return taiwanCurrentDateTimestamp() - 86400000
}

/**
 * 產生預設的日期範圍值。
 * 轉換成台北時間後，得到昨天的時間戳記，再減去六天的時間戳記
 * @returns {Array<number>} - 預設的日期範圍值，包含起始時間戳記和結束時間戳記。
 */

export const defaultMultiValue = (): Array<number> => {
  const yesterday = defaultSingleValue()
  const lastWeek = yesterday - (86400000 * 6)
  return [ lastWeek, yesterday ]
}

/**
 * 產生 n 日的日期範圍值。
 * 轉換成台北時間後，得到昨天的時間戳記，再減去 n-1 天的時間戳記
 * @param {number} days - 要產生的日期範圍值的天數。
 * @returns {Array<number>} - 預設的日期範圍值，包含起始時間戳記和結束時間戳記。
 */

export const MultiRangeValue = (days: number): Array<number> => {
  const daysMinusOne = days - 1
  const yesterday = defaultSingleValue()
  const lastWeek = yesterday - (86400000 * daysMinusOne)
  return [ lastWeek, yesterday ]
}

/**
 * 產生預設的年份範圍值。
 * 取得當前的台灣日期與時間，並取得年份，再減去一年
 * @returns {number} - 預設的年份範圍值。
 */

export const defaultYearValue = (): number => {
  const [year] = convertTaipeiTimestampToDate(taiwanCurrentDateTimestamp()).date.split('/')
  const currentYear = parseInt(year)
  return currentYear - 1
}

export const disabledDates = (time: Date): boolean => {
  const taipeiYesterday = defaultSingleValue()
  const taipeiToday = taipeiYesterday + 86400000
  const selectTime = convertTimestampToTaipeiTimestamp(time.getTime())
  return (
    selectTime > taipeiToday
  )
}

export const weekForEn = (index: number) => {
  if (index === 0) {
    return 'Mon'
  } else if (index === 1) {
    return 'Tue'
  } else if (index === 2) {
    return 'Wed'
  } else if (index === 3) {
    return 'Thu'
  } else if (index === 4) {
    return 'Fri'
  } else if (index === 5) {
    return 'Sat'
  } else if (index === 6) {
    return 'Sun'
  }
}

export const weekForVi = (index: number) => {
  if (index === 0) {
    return 'Hai'
  } else if (index === 1) {
    return 'Ba'
  } else if (index === 2) {
    return 'Tư'
  } else if (index === 3) {
    return 'Năm'
  } else if (index === 4) {
    return 'Sáu'
  } else if (index === 5) {
    return 'Bảy'
  } else if (index === 6) {
    return 'Chủ<br />nhật'
  }
}

export class DatePicker {
  public state: DatePickerType
  protected tmpState: DatePickerType

  constructor () {
    this.state = {
      type: 'single',
      value: {
        single: defaultSingleValue(),
        multi: defaultMultiValue(),
        year: defaultYearValue()
      },
      start: 0,
      end: 0,
      apitype: '3min'
    }
    this.tmpState = { ...JSON.parse(JSON.stringify(this.state)) }
  }

  static fromJSON (json: { state: DatePickerType; tmpState: DatePickerType }) {
    const instance = new DatePicker()
    instance.state = json.state
    instance.tmpState = json.tmpState
    return instance
  }

  setTmpState (newState: DatePickerType) {
    this.tmpState = { ...JSON.parse(JSON.stringify(newState)) }
  }

  getState () {
    switch (this.state.type) {
      case 'single':
        this.state.start = this.state.value.single / 1000
        this.state.end = this.state.start + 86399
        this.state.apitype = this.state.apitype || '3min'
        break
      case 'multi':
        this.state.start = this.state.value.multi[0] / 1000
        this.state.end = (this.state.value.multi[1] / 1000) + 86399
        this.state.apitype = '1day'
        break
      case 'year': {
        const year = this.state.value.year
        this.state.start = convertTimestampToTaipeiTimestamp(new Date(`${year}/01/01`).getTime()) / 1000
        this.state.end = (convertTimestampToTaipeiTimestamp(new Date(`${year}/12/31`).getTime()) / 1000) + 86399
        this.state.apitype = this.state.apitype || '1week'
        break
      }
    }
  }

  setState (newState: DatePickerType) {
    this.state = JSON.parse(JSON.stringify(newState))
  }

  setStateType (type: 'single' | 'multi' | 'year') {
    this.state.type = type
    this.state.value = {
      single: defaultSingleValue(),
      multi: defaultMultiValue(),
      year: defaultYearValue()
    }
  }

  toJSON () {
    return {
      state: this.state,
      tmpState: this.tmpState
    }
  }
}

export class DatePickerNew {
  type: 'single' | 'multi' | 'year'
  value: {
    single: number
    multi: number[]
    year: number
  }
  start: number
  end: number
  apitype: string
  constructor () {
    this.type = 'single'
    this.value = {
      single: defaultSingleValue(),
      multi: defaultMultiValue(),
      year: defaultYearValue()
    }
    this.start = 0
    this.end = 0
    this.apitype = '3min'
  }

  getState () {
    switch (this.type) {
      case 'single':
        this.start = this.value.single / 1000
        this.end = this.start + 86399
        this.apitype = this.apitype || '3min'
        break
      case 'multi':
        this.start = this.value.multi[0] / 1000
        this.end = (this.value.multi[1] / 1000) + 86399
        this.apitype = this.apitype || '1day'
        break
      case 'year': {
        const year = this.value.year
        this.start = convertTimestampToTaipeiTimestamp(new Date(`${year}/01/01`).getTime()) / 1000
        this.end = (convertTimestampToTaipeiTimestamp(new Date(`${year}/12/31`).getTime()) / 1000) + 86399
        this.apitype = this.apitype || '1week'
        break
      }
    }
  }

  setState (newState: DatePickerType) {
    this.type = newState.type
    this.value = newState.value
    this.apitype = newState.apitype
    this.getState()
  }

  setStateType (type: 'single' | 'multi' | 'year', apitype: string) {
    this.type = type
    this.value = {
      single: defaultSingleValue(),
      multi: defaultMultiValue(),
      year: defaultYearValue()
    }
    this.apitype = apitype
    this.getState()
  }

  setApitype (apitype: string) {
    this.apitype = apitype
    this.getState()
  }

  toJSON () {
    return {
      type: this.type,
      value: this.value,
      start: this.start,
      end: this.end,
      apitype: this.apitype,
      __class__: 'DatePickerNew'
    }
  }

  static fromJSON (jsonItem: Record<string, unknown>) {
    const instance = new DatePickerNew()
    instance.type = jsonItem.type as 'single' | 'multi' | 'year'
    instance.value = jsonItem.value as { single: number, multi: number[], year: number }
    instance.start = jsonItem.start as number
    instance.end = jsonItem.end as number
    instance.apitype = jsonItem.apitype as string
    return instance
  }
}
