import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersist from 'pinia-plugin-persist'
import router from './router/index'
import directives from '@/directives/index'
import * as Sentry from '@sentry/vue'
import VueGtag from 'vue-gtag-next'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { createGtm } from '@gtm-support/vue-gtm'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import './assets/styles/vue-smart-widget.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { i18n } from './plugins/i18n'
import '@/assets/styles/style.css'
import '@/assets/styles/root.css'
import 'virtual:svg-icons-register'
import App from './App.vue'

const isProduction = import.meta.env.MODE === 'production'

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://cae1b027d6f3062e5571d044287ba1b0@o4507938605760512.ingest.us.sentry.io/4507938608250880',
  environment: import.meta.env.MODE,
  enabled: isProduction,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration()
  ],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  tracePropagationTargets: [
    // 'localhost',
    // /^https:\/\/staging\.warroom3\.goodlinker\.io\//,
    /^https:\/\/warroom\.goodlinker\.io\//
  ],
  beforeSend (event) {
    if (window.location.hostname === 'localhost') {
      return null
    }
    return event
  },
  replaysSessionSampleRate: isProduction ? 0.1 : 1.0,
  replaysOnErrorSampleRate: 1.0
})

for (const [ key, component ] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

const pinia = createPinia()
pinia.use(piniaPluginPersist)

app.use(directives)
app.use(pinia)
app.use(router)
app.use(i18n)
app.use(VueReCaptcha, {
  siteKey: '6Ldxl0QjAAAAACA-tQVOS-yldYUcjNC7dk1UVI8L',
  loaderOptions: {} // Add an empty object for the loaderOptions property
})

app.use(VueGtag, {
  property: {
    id: 'G-CJQLPCGW6K'
  },
  useDebugger: !isProduction
})

app.use(createGtm({
  id: 'GTM-T4VRDH8',
  debug: !isProduction,
  defer: true,
  vueRouter: router,
  trackOnNextTick: false
}))
app.use(VueDOMPurifyHTML)
app.mount('#app')
