import { defineStore } from 'pinia'
import { useUserStore } from '@/stores'

interface PermissionSettings {
  permission_setting_boolean_value: boolean | null
  permission_setting_id: number
  permission_setting_number_value: number | null
  permission_setting_string_value: string | null
  permission_setting_type_id: number
  permission_setting_type_name: string
  permission_setting_type_value_type: string
}

interface Permissions {
  id: string
  name: string
  show_name: string
  permission_settings: PermissionSettings[]
}

interface Plans {
  created_at: string
  guard_name: string
  id: number
  is_assignable: number
  module_id: number
  name: string
  service_id: number
  show_name: string
  updated_at: string
}

interface SelfPermissions {
  [key: string]: boolean | number | null
}

export const usePermissionStore = defineStore('GOODLINKER_PERMISSION', {
  state: () => ({
    permissions: [] as Permissions[],
    plans: [] as Plans[],
    self: {} as SelfPermissions
  }),
  actions: {
    setSelfPermissions (userType: string) {
      const convertToCamelCase = (str: string) => {
        return str
          .toLowerCase()
          .split(' ')
          .map((word, index) =>
            // 將每個單詞的首字母轉換為大寫，除了第一個單詞前加上 "can"
            index === 0 ? `can${word.charAt(0).toUpperCase() + word.slice(1)}` : word.charAt(0).toUpperCase() + word.slice(1))
          .join('')
      }
      const combineArray = [ ...this.permissions, ...this.plans ]
      this.self = combineArray.reduce<SelfPermissions>((acc, item) => {
        const name = convertToCamelCase(item.name)
        acc[name] = Object.hasOwn(acc, name)
        if ('permission_settings' in item && item.permission_settings.length > 0) {
          item.permission_settings.forEach(setting => {
            switch (setting.permission_setting_type_name) {
              case '自訂首頁頁面數量上限':
                acc.canCustomPageAmount = setting.permission_setting_number_value
                break
              case '分析圖表數量限制':
                acc.canAnalysisChartAmount = setting.permission_setting_number_value
                break
              case 'excel報表數量限制':
                acc.canExcelReportsAmount = setting.permission_setting_number_value
                break
              case '通用圖表數量限制':
                acc.canChartStudioAmount = setting.permission_setting_number_value
                break
              default:
                break
            }
          })
        }
        return acc
      }, {})

      // console.log('permission', this.self)

      const useStore = useUserStore()
      const planName = useStore.currentCompany.planName

      if (userType === 'official' || userType === 'observer') {
        // 取得所有權限，全部設為 true，跟數量有關的要自己處理 -> switch (setting.permission_setting_type_name)
        // 擋住 Excel 報表免費版的權限
        this.self = {
          canReadAlarmAnalysisPage: true,
          canReadDashboard: true,
          canManageDataCenter: true,
          canReadHistoryChart: true,
          canReadHistoryChartInDay: true,
          canReadHistoryChartByDay: true,
          canReadHistoryChartByYear: true,
          canReadAnalysisChart: true,
          canReadAiAnalysis: true,
          canManageAnalysisChart: true,
          canAnalysisChartAmount: 10,
          canDownloadHistoryExcelInDay: true,
          canDownloadHistoryExcelByDay: true,
          canReadCustomPage: true,
          canManageCustomPage: true,
          canCustomPageAmount: 10,
          canManageUser: userType === 'official',
          canManageTag: true,
          canManageTagGroups: true,
          canReadUser: true,
          canReadDeviceInfo: true,
          canManageExcelReports: planName !== 'free',
          canExcelReportsAmount: planName === 'free' ? 0 : (planName === 'basic' ? 3 : 10),
          canChartStudio: true,
          canChartStudioAmount: 10
        }
      }
    }
  }
})
