import {i18n} from './i18n'
import {DatePickerType} from '@/interface/DatePickerType'

const { t } = i18n.global

/*
 * 輸入 timestamp，取得台灣的日期、時間，與週
 * timestamp 是處理過的台灣時間 +8，而非 UTC 時間 +0
*/

const timeZone = 'Asia/Taipei'
export const taiwanDateOptions: Intl.DateTimeFormatOptions = {
  timeZone,
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
}
export const taiwanTimeOptions: Intl.DateTimeFormatOptions = {
  timeZone,
  hourCycle: 'h23',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit'
}
export const taiwanWeekdayOptions: Intl.DateTimeFormatOptions = {
  timeZone,
  weekday: 'short'
}

/*
 * 取得當前的台灣日期與時間
*/

export const taiwanCurrentDate = {
  date: new Date().toLocaleDateString('zh-TW', taiwanDateOptions),
  time: new Date().toLocaleTimeString('en-US', taiwanTimeOptions),
  week: new Date().toLocaleString('en-US', taiwanWeekdayOptions)
}

/*
 * 取得當前台灣日期，並轉成 timestamp (UTC+8)，時間為 00:00:00
*/

export const taiwanCurrentDateTimestamp = () => {
  const now = new Date()
  const taiwanNow = new Intl.DateTimeFormat('zh-TW', { timeZone: 'Asia/Taipei', year: 'numeric', month: '2-digit', day: '2-digit' }).format(now)

  const [ year, month, day ] = taiwanNow.split('/')
  const taiwanToday = `${year}-${month}-${day}T00:00:00+08:00`
  const date = new Date(taiwanToday)
  return date.getTime()
}

/*
 * 取得當前台灣日期，並轉成 timestamp (UTC+8)，時間為 23:59:59
*/

export const taiwanCurrentDateTimestampEnd = () => {
  const now = new Date()
  const taiwanNow = new Intl.DateTimeFormat('zh-TW', { timeZone: 'Asia/Taipei', year: 'numeric', month: '2-digit', day: '2-digit' }).format(now)

  const [ year, month, day ] = taiwanNow.split('/')
  const taiwanToday = `${year}-${month}-${day}T23:59:59+08:00`
  const date = new Date(taiwanToday)
  return date.getTime()
}

export const taiwanCurrentYearTimestamp = () => {
  const now = new Date()
  const year = now.getFullYear()
  const start = `${year}-01-01T00:00:00+08:00`
  const end = `${year}-12-31T23:59:59+08:00`
  const startDate = new Date(start)
  const endDate = new Date(end)
  return {
    start: startDate.getTime(),
    end: endDate.getTime()
  }
}


/*
 * 取得當地的 timezone，轉成台北時間
*/

export const convertTimestampToTaipeiTimestamp = (timestamp: number) => {
  const showOffset = (timestamp: number) => {
    const date = new Date(timestamp)
    return date.getTimezoneOffset() / 60
  }
  const offset = showOffset(timestamp)
  const offsetTaipei = -(offset) - 8
  return timestamp + (offsetTaipei * 60 * 60 * 1000)
}

export const convertTaipeiTimestampToDate = (timestamp: number) => {
  const date = new Date(timestamp)
  const dateString = date.toLocaleDateString('zh-TW', taiwanDateOptions)
  const timeString = date.toLocaleTimeString('en-US', taiwanTimeOptions)
  const weekdayString = date.toLocaleString('en-US', taiwanWeekdayOptions)

  return {
    date: dateString,
    time: timeString,
    week: weekdayString
  }
}

export const convertToDateAndWeek = (value: DatePickerType['value'], type: string): string => {
  if (type === 'single') {
    const timestamp = value.single
    const { date, week } = convertTaipeiTimestampToDate(timestamp)
    const weekI18n = t(`pages.unit.${week}`)
    return `${date}(${weekI18n})`
  } else if (type === 'multi') {
    const timestamp = value.multi
    const { date: startDate, week: startWeek } = convertTaipeiTimestampToDate(timestamp[0])
    const { date: endDate, week: endWeek } = convertTaipeiTimestampToDate(timestamp[1])
    const startWeekI18n = t(`pages.unit.${startWeek}`)
    const endWeekI18n = t(`pages.unit.${endWeek}`)
    return `${startDate}(${startWeekI18n}) - ${endDate}(${endWeekI18n})`
  } else if (type === 'year') {
    return `${value.year}`
  }
  return ''
}

/*
* UTC + 0 的 timestamp 專用
* 傳遞 timestamp (UTC + 0)，回傳台灣的日期、時間，與週
*/

export const convertTimestampToDate = (timestamp: number) => {
  const date = new Date(timestamp)
  return date.toLocaleDateString('zh-TW', {year: 'numeric', month: '2-digit', day: '2-digit'})
}

export const convertTimestampToTime = (timestamp: number) => {
  const date = new Date(timestamp)
  return date.toLocaleTimeString('en-US', {hourCycle: 'h23', hour: '2-digit', minute: '2-digit'})
}

export const convertTimestampToDateAndTime = (timestamp: number) => {
  const date = convertTimestampToDate(timestamp)
  const time = convertTimestampToTime(timestamp)
  return `${date} ${time}`
}

export const convertDateToDateAndTime = (date: Date) => {
  const dateString = date.toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit' })
  const timeString = date.toLocaleTimeString('en-US', { hourCycle: 'h23', hour: '2-digit', minute: '2-digit' })
  return `${dateString} ${timeString}`
}

export const convertToHHMMSS = (val: number) => {
  const hours = Math.floor(val / 3600)
  const minutes = Math.floor((val % 3600) / 60)
  const seconds = val % 60

  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')
  const formattedSeconds = seconds.toString().padStart(2, '0')

  return `${formattedHours} ${t('pages.unit.hour')} ${formattedMinutes} ${t('pages.unit.minute')} ${formattedSeconds} ${t('pages.unit.second')}`
}

export const getWeekRange = (year: number, week: number) => {
  const firstMonday = getFirstMonday(year).getDate() // 取得第一個禮拜一的日期
  const startOfWeek = new Date(year, 0, firstMonday + ((week - 1) * 7)) // 計算週數的起始日期
  const endOfWeek = new Date(startOfWeek.getTime() + (6 * 24 * 60 * 60 * 1000)) // 計算週數的結束日期
  const startDateString = formatDate(startOfWeek)
  const endDateString = formatDate(endOfWeek)
  return `${startDateString} ~ ${endDateString}`
}

// 取得特定年份的第一個禮拜一日期
export const getFirstMonday = (year: number) => {
  const firstDayOfYear = new Date(year, 0, 1)
  const firstDayOfWeek = firstDayOfYear.getDay()
  const daysToAdd = (8 - firstDayOfWeek) % 7
  return new Date(year, 0, 1 + daysToAdd)
}

function formatDate (date: Date) {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  return `${year}/${month}/${day}`
}

const splitTime = (interval: number) => {
  const result = []
  for (let i = interval; i <= 60 * 24; i += interval) {
    const hours = Math.floor(i / 60)
    const minutes = i % 60
    result.push(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`)
  }
  return result
}

/**
 * 
 * @param interval 分鐘間隔
 * @returns 時間軸
 */

export const getTimeAxisData = (interval: number) => {
  return splitTime(interval)
}
