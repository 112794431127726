import { defineStore } from 'pinia'
import { ElMessageBox } from 'element-plus'
import { i18n } from '@/plugins/i18n'
import { useShadowStore } from '@/stores/technical/shadow'
import { useUserStore } from '@/stores/core/user'
import { useEventHandlerStore } from '@/stores/feature/eventHandler'
import { getCompaniesLesies } from '@/fetch/shadow'
import 'element-plus/theme-chalk/el-message-box.css'
import type { SensorListByTag } from '@/models/interface/SensorListByTag'
import type { SensorDataByTag, CompanyTag, PositionTag } from '@/interface/CompanyTagType'
import http from '@/plugins/http'
import httpErrorHandler from '@/plugins/httpErrorHandler'

const { t, te } = i18n.global

interface CompanyTagStore {
  companyTag: CompanyTag[]
  positionTag: PositionTag[]
  sensorDataByTag: SensorDataByTag[]
  localeSensorTags: CompanyTag[]
}

export const useCompanyTagStore = defineStore('companyTag', {
  state: (): CompanyTagStore => ({
    companyTag: [],
    positionTag: [],
    sensorDataByTag: [],
    localeSensorTags: []
  }),
  actions: {
    /**
     * 取得單一使用者根據所屬的標籤群組，可看到的標籤
     * @param companyId 
     */
    async fetchGetOwnTags (companyId: number) {
      const userStore = useUserStore()
      const eventHandlerStore = useEventHandlerStore()
      if (userStore.user.position === null) {
        const logData = {
          path: 'companyTag.ts',
          action: 'userStore.user.position 為 null',
          info: `使用者的 /me api 資料為 ${JSON.stringify(userStore.user)}`
        }
        eventHandlerStore.setLogData(logData)
        ElMessageBox.alert(t('messages.noPosition.message'), t('messages.noPosition.title'), {
          confirmButtonText: t('actions.btn--confirm')
        })
        return
      }
      await userStore.getCompanyUserMe()
      const positionId = userStore.user.position?.id
      try {
        const res = await http.get(`/common/v3/companies/${companyId}/position/position-tag/${positionId}`)
        this.positionTag = res.data.data
      } catch (error) {
        httpErrorHandler(error)
      }
    },
    /**
     * 取得單一公司的所有標籤，通常是給有管理權限的人使用
     * @param companyId 
     */
    async fetchGetAllTags (companyId: number) {
      try {
        const res = await http.get(`/warroom/v3/companies/${companyId}/tags`)
        this.companyTag = res.data.data
        await this.transformLocaleSensorTags()
      } catch (error) {
        httpErrorHandler(error)
      }
    },
    /**
     * 取得單一公司的所有標籤對應的感測器資料
     */
    async fetchGetAllSensors (companyId: number) {
      try {
        const res = await http.get(`/warroom/v3/companies/${companyId}/sensors`)
        this.sensorDataByTag = res.data.data
        await this.fetchSensorGroup(companyId)
      } catch (error) {
        httpErrorHandler(error)
      }
    },
    /**
     * 取得單一使用者根據 ownTags 而看到的感測器資料
     */
    async fetchGetOwnSensors () {
      const promises = this.positionTag.map(tag => http.get(`/common/v3/tags/tag-sensors/${tag.encryptId}`))
      const res = await Promise.all(promises)
      const data = res.map(res => res.data.data).flat()
      this.sensorDataByTag = []
      data.forEach(item => {
        const sensorDataIndex = this.sensorDataByTag.findIndex(sensorData => sensorData.id === item.sensorId)
        const tag = {
          id: item.tagId,
          tagName: item.tagName,
          source: item.tagSource,
          officialType: item.officialType || '',
          sensors_count: item.sensors_count || null
        }
        if (sensorDataIndex !== -1) {
          this.sensorDataByTag[sensorDataIndex].tags.push(tag)
        } else {
          this.sensorDataByTag.push({
            id: item.sensorId,
            sensorType: item.sensorType,
            sensorName: item.sensorName,
            sensorAlias: item.sensorAlias,
            tags: [tag],
            lesi: {
              alias: item.thingAlias,
              thingName: item.thingName
            },
            groupName: []
          })
        }
      })
    },
    /**
     * 取得單一公司所有感測器的群組
     * @param companyId 
     */
    async fetchSensorGroup (companyId: number) {
      try {
        await getCompaniesLesies()
        const shadowStore = useShadowStore()
        const payload = {
          things: shadowStore.thingNameList
        }
        const res = await http.post(`/warroom/v3/companies/${companyId}/sensors/groups`, payload)
        const groups = res.data.data.items
        this.sensorDataByTag.map(item => {
          if (groups[item.lesi.thingName]) {
            item.groupName = groups[item.lesi.thingName][item.sensorName]?.groupAias || '無群組'
            return item
          }
        })
      } catch (error) {
        httpErrorHandler(error)
      }
    },
    /**
     * 將 tagName 轉成本地化翻譯
     */
    async transformLocaleSensorTags () {
      this.localeSensorTags = this.companyTag.map(item => {
        const newItem = { ...item }
        newItem.tagName = te(`sensorTag.${newItem.tagName}`) ? t(`sensorTag.${newItem.tagName}`) : newItem.tagName
        return newItem
      })
    },
    async handleGetAllTagsAndSensors () {
      const userStore = useUserStore()
      const eventHandlerStore = useEventHandlerStore()
      if (userStore.user.position === null) {
        const logData = {
          path: 'companyTag.ts',
          action: 'userStore.user.position 為 null',
          info: `使用者的 /me api 資料為 ${JSON.stringify(userStore.user)}`
        }
        eventHandlerStore.setLogData(logData)
        ElMessageBox.alert(t('messages.noPosition.message'), t('messages.noPosition.title'), {
          confirmButtonText: t('actions.btn--confirm')
        })
        return
      }
      const companyId = await userStore.getCompanyId()
      await this.fetchGetAllTags(companyId)
      await this.fetchGetAllSensors(companyId)
    },
    async handleGetOwnTagsAndSensors () {
      const userStore = useUserStore()
      const companyId = await userStore.getCompanyId()
      await this.fetchGetOwnTags(companyId)
      await this.fetchGetOwnSensors()
    },
    /**
     * 取得單一使用者根據所屬的標籤群組，可看到的感測器資料，並轉成感測器選單格式
     * 用於 ExcelTemplate.vue 的感測器選單
     * 用於 ChartEdit.vue 的感測器選單
     */
    async handleSensorListByTag () {
      await this.handleGetOwnTagsAndSensors()
      const sensorListByTag: SensorListByTag[] = []
      this.sensorDataByTag.forEach(sensor => {
        if (sensor.sensorType === 'string') return
        sensor.tags.forEach(tag => {
          if (!sensorListByTag.find(item => item.value === tag.tagName)) {
            sensorListByTag.push({
              value: tag.tagName,
              label: tag.tagName,
              sensorId: sensor.id,
              children: [
                {
                  value: sensor.sensorName,
                  label: sensor.sensorAlias,
                  sensorId: sensor.id
                }
              ]
            })
          } else {
            const foundItem = sensorListByTag.find(item => item.value === tag.tagName)
            if (foundItem) {
              foundItem.children.push({
                value: sensor.sensorName,
                label: sensor.sensorAlias,
                sensorId: sensor.id
              })
            }
          }
        })
      })
      return sensorListByTag
    }
  }
})
