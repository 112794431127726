import protoRoot from '@/plugins/ProtoRoot'
import { useUserStore, useShadowStore } from '@/stores'
import http from '@/plugins/http'
import type { Pages } from '@/models/interface/home'
import type { SensorData } from '@/models/interface/chartType'
import type { ProtobufList } from '@/interface/ShadowType'

export const getSensorList = (pages: Pages) => {
  interface SensorList {
    [key: string]: string[]
  }
  let uniq = []
  const sensorList:SensorList = {}
  Object.values(pages.layouts).map(page => {
    page.map(obj => {
      obj.child.map(item => {
        if (!sensorList[item.thingName]) sensorList[item.thingName] = []
        sensorList[item.thingName].push(item.sensorName)
        uniq = [...new Set(sensorList[item.thingName])]
        sensorList[item.thingName] = uniq
        return item
      })
      return obj
    })
    return page
  })
  return sensorList
}

export const getSensorListFromHistory = (showData: SensorData[]) => {
  const sensorList:string[] = []
  showData.forEach((item) => {
    sensorList.push(item.thingName)
  })
  return new Set(sensorList)
}

export const getCompaniesLesies = async () => {
  const userStore = useUserStore()
  const sub = await userStore.getAuthSub()
  const companyId = await userStore.getCompanyId()
  try {
    const res = await new Promise((resolve, reject) => {
      http.get(`/common/v3/companies/${companyId}/lesies/status`)
        .then((res) => {
          if (res.data.data.length === 0) {
            throw new Error('Companies Lesies data is empty')
          }
          const shadowStore = useShadowStore()
          if (!shadowStore) return
          shadowStore.lesiList = res.data.data
          resolve(res)
        })
        .catch((err) => reject(err))
    })
    await new Promise((resolve, reject) => {
      const shadowStore = useShadowStore()
      if (!shadowStore) return
      const payload = {
        thing: shadowStore.lesiList,
        user: [
          {
            sub,
            companyId
          }
        ]
      }
      http.post('/warroom/v3/shadow-lambda/realtime', payload)
        .then((res) => {
          shadowStore.shadowList = res.data.data.items.original.data
          resolve(res)
        })
        .catch((err) => reject(err))
    })
    dealProtobuf()
    return res
  } catch (error) {
    // NOTE: errorPass: 儀表板相關 API 錯誤時不跳出 alert
    console.log('Response error', error)
    return Promise.reject(error)
  }
}

const dealProtobuf = () => {
  const shadowStore = useShadowStore()
  if (!shadowStore) return
  shadowStore.thingNameList = []
  shadowStore.shadowDisplayType = []
  shadowStore.protobufList = []
  shadowStore.lesiList.forEach(item => {
    shadowStore.thingNameList.push(item.thingName)
    if (shadowStore.customPage === true) {
      shadowStore.shadowDisplayType.push('sensor-type')
    } else {
      shadowStore.shadowDisplayType.push(shadowStore.type)
    }
    shadowStore.shadowList.map(shadow => {
      if (shadow.thingName === item.thingName) {
        shadowStore.protobufList.push(parseProtobuf(shadow.d))
      }
      return shadow
    })
  })
}

const parseProtobuf = (item: string): ProtobufList => {
  const PBMessageResponse = protoRoot.lookupType('goodlinker.thing.ThingData')
  // const PBMessageResponse = protoRoot.lookup('goodlinker.thing.ThingData')
  const protobufData = base64ToArrayBuffer(item)
  const message = PBMessageResponse.decode(protobufData)
  const object = PBMessageResponse.toObject(message, {
    longs: String,
    enums: Number,
    bytes: String,
    defaults: true,
    oneofs: true
  })
  return object as ProtobufList
}

const base64ToArrayBuffer = (base64: string) => {
  const binaryString = window.atob(base64)
  const bytes = new Uint8Array(binaryString.length)
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes
}
