import { ElLoading } from 'element-plus'
let loading: ReturnType<typeof ElLoading.service> | null = null
let loadingCount = 0

export const showLoading = (text?: string) => {
  if (loadingCount === 0) {
    loading = ElLoading.service({
      lock: true,
      text
    })
    loadingCount++
  }
}

export const closeLoading = () => {
  if (loadingCount <= 0) return
  loadingCount--
  if (loadingCount === 0) {
    if (loading) {
      loading.close()
    }
  }
}
