import * as protobuf from 'protobufjs/light'

const $protobuf = protobuf

const $root = ($protobuf.roots.default || ($protobuf.roots.default = new $protobuf.Root()))
  .addJSON({
    goodlinker: {
      nested: {
        thing: {
          nested: {
            ThingData: {
              fields: {
                timestamp: {
                  type: 'google.protobuf.Timestamp',
                  id: 1
                },
                thingAlias: {
                  type: 'string',
                  id: 2
                },
                sensor: {
                  rule: 'repeated',
                  type: 'Sensor',
                  id: 3
                }
              }
            },
            Sensor: {
              oneofs: {
                sensorType: {
                  oneof: [
                    'lamp',
                    'counter',
                    'timer',
                    'detector',
                    'analog',
                    'stringValue'
                  ]
                }
              },
              fields: {
                id: {
                  type: 'string',
                  id: 1
                },
                alias: {
                  type: 'string',
                  id: 2
                },
                type: {
                  type: 'TYPE',
                  id: 3
                },
                status: {
                  type: 'STATUS',
                  id: 4
                },
                ruleEnable: {
                  type: 'bool',
                  id: 5
                },
                ruleName: {
                  type: 'string',
                  id: 6
                },
                lamp: {
                  type: 'Lamp',
                  id: 7
                },
                counter: {
                  type: 'Counter',
                  id: 8
                },
                timer: {
                  type: 'Timer',
                  id: 9
                },
                detector: {
                  type: 'Detector',
                  id: 10
                },
                analog: {
                  type: 'Analog',
                  id: 11
                },
                stringValue: {
                  type: 'String',
                  id: 12
                }
              },
              nested: {
                TYPE: {
                  values: {
                    LAMP: 0,
                    COUNTER: 1,
                    TIMER: 2,
                    DETECTOR: 3,
                    ANALOG: 4,
                    STRING: 5
                  }
                },
                STATUS: {
                  values: {
                    OFF: 0,
                    NORMAL: 1,
                    ABNORMAL: 2,
                    WARNING: 3
                  }
                }
              }
            },
            Lamp: {
              fields: {
                value: {
                  type: 'STATUS',
                  id: 1
                },
                ruleTriggerColor: {
                  type: 'STATUS',
                  id: 2
                },
                ruleSustainedMilliseconds: {
                  type: 'uint32',
                  id: 3
                },
                colorAccumultation: {
                  rule: 'repeated',
                  type: 'AccumulationTime',
                  id: 4
                }
              },
              nested: {
                STATUS: {
                  values: {
                    NONE: 0,
                    RED: 1,
                    YELLOW: 2,
                    GREEN: 3,
                    BLUE: 4,
                    WHITE: 5
                  }
                },
                AccumulationTime: {
                  fields: {
                    color: {
                      type: 'STATUS',
                      id: 1
                    },
                    accumulationMilliseconds: {
                      type: 'uint32',
                      id: 2
                    },
                    scheduleAccumulationMilliseconds: {
                      type: 'uint32',
                      id: 3
                    }
                  }
                }
              }
            },
            Counter: {
              fields: {
                value: {
                  type: 'uint32',
                  id: 1
                },
                ruleLimit: {
                  type: 'uint32',
                  id: 2
                }
              }
            },
            Timer: {
              fields: {
                value: {
                  type: 'uint32',
                  id: 1
                },
                ruleLimit: {
                  type: 'uint32',
                  id: 2
                }
              }
            },
            Detector: {
              fields: {
                value: {
                  type: 'bool',
                  id: 1
                },
                ruleLimit: {
                  type: 'bool',
                  id: 2
                }
              }
            },
            Analog: {
              fields: {
                value: {
                  type: 'float',
                  id: 1
                },
                unit: {
                  type: 'string',
                  id: 2
                },
                ruleUpperLimit: {
                  type: 'float',
                  id: 3
                },
                ruleLowerLimit: {
                  type: 'float',
                  id: 4
                }
              }
            },
            String: {
              fields: {
                value: {
                  type: 'string',
                  id: 1
                }
              }
            }
          }
        }
      }
    },
    google: {
      nested: {
        protobuf: {
          nested: {
            Timestamp: {
              fields: {
                seconds: {
                  type: 'int64',
                  id: 1
                },
                nanos: {
                  type: 'int32',
                  id: 2
                }
              }
            },
            DoubleValue: {
              fields: {
                value: {
                  type: 'double',
                  id: 1
                }
              }
            },
            FloatValue: {
              fields: {
                value: {
                  type: 'float',
                  id: 1
                }
              }
            },
            Int64Value: {
              fields: {
                value: {
                  type: 'int64',
                  id: 1
                }
              }
            },
            UInt64Value: {
              fields: {
                value: {
                  type: 'uint64',
                  id: 1
                }
              }
            },
            Int32Value: {
              fields: {
                value: {
                  type: 'int32',
                  id: 1
                }
              }
            },
            UInt32Value: {
              fields: {
                value: {
                  type: 'uint32',
                  id: 1
                }
              }
            },
            BoolValue: {
              fields: {
                value: {
                  type: 'bool',
                  id: 1
                }
              }
            },
            StringValue: {
              fields: {
                value: {
                  type: 'string',
                  id: 1
                }
              }
            },
            BytesValue: {
              fields: {
                value: {
                  type: 'bytes',
                  id: 1
                }
              }
            }
          }
        }
      }
    }
  })

export default $root
