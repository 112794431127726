import { useUserStore } from '@/stores'
import type { Directive, DirectiveBinding } from 'vue'

interface ElType extends HTMLElement {
  parentNode: HTMLElement
}

const permission: Directive = {
  mounted (el: ElType, binding: DirectiveBinding) {
    const userStore = useUserStore()
    const { value } = binding
    const userType = userStore && userStore.user.userType
    const permissions = userStore && userStore.user.permissions
    const plans = userStore && userStore.user.plans
    const requiredPermissions = value.replace(/([A-Z])/g, ' $1').toLowerCase().trim()

    let hasPermission = false

    hasPermission = Boolean(plans.find(item => item.name === requiredPermissions) &&
      permissions.find(item => item.name === requiredPermissions))

    if (userType === 'official') {
      hasPermission = true
    }

    if (!hasPermission) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  }
}

export default permission
