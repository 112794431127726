import { i18n } from '@/plugins/i18n'
import { DatePickerNew } from '@/plugins/DatePicker'
import { ElMessageBox } from 'element-plus'
import 'element-plus/theme-chalk/el-message-box.css'


const { t } = i18n.global

const clearUserData = (): void => {
  localStorage.removeItem('USER')
}

export const versionControlService = {
  VITE_APP_VERSION: import.meta.env.VITE_APP_VERSION,

  setAppVersion (): void {
    localStorage.setItem('VERSION', this.VITE_APP_VERSION)
  },

  checkVersion () {
    const clientVersion = localStorage.getItem('VERSION') || null
    return !(clientVersion === null || clientVersion !== this.VITE_APP_VERSION)
  },

  handleVersionMismatch (routeName?: string) {
    if (routeName === 'Login') {
      clearUserData()
    } else {
      ElMessageBox.alert(t('messages.version.message'), t('messages.version.title'), {
        confirmButtonText: t('actions.btn--confirm'),
        callback: () => {
          this.setAppVersion()
          window.location.reload()
        }
      }).then(r =>
        r === 'confirm')
    }
  }
}

/**
 * 註冊類別，讓深拷貝時可以保留 Class 的 fromJSON 方法
 * @param name 類別名稱
 * @param ClassConstructor 類別建構函數
 */

const classRegistry = new Map<string, any>()
const registerClass = (name: string, ClassConstructor: any) => {
  classRegistry.set(name, ClassConstructor)
}

registerClass('DatePickerNew', DatePickerNew)

export const deepCloneWithClass = (obj: any) => {
  return JSON.parse(JSON.stringify(obj), (key, value) => {
    if (typeof value === 'object' && value !== null) {
      const Constructor = classRegistry.get(value.constructor?.name)
      if (Constructor?.fromJSON) {
        return Constructor.fromJSON(value)
      }
    }
    return value
  })
}
